export default function InfoIconOutline({
  width = 16,
  height = 16,
  fill = 'currentColor',
  ...rest
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 14 14' fill='none' {...rest}>
      <g clip-path='url(#info-icon-outline)'>
        <path
          fill={fill}
          d='M7 .14c-5.282 0-8.582 5.717-5.942 10.29 2.64 4.573 9.242 4.573 11.882 0A6.86 6.86 0 0 0 13.86 7 6.867 6.867 0 0 0 7 .14Zm0 12.665c-4.47 0-7.262-4.837-5.028-8.707 2.234-3.87 7.82-3.87 10.054 0 .51.882.778 1.883.778 2.902a5.811 5.811 0 0 1-5.805 5.805Zm1.055-2.639a.528.528 0 0 1-.528.528A1.055 1.055 0 0 1 6.47 9.638V7a.528.528 0 1 1 0-1.055c.583 0 1.056.472 1.056 1.055v2.638c.291 0 .528.237.528.528ZM5.944 4.098a.792.792 0 1 1 1.583 0 .792.792 0 0 1-1.583 0Z'
        />
      </g>
      <defs>
        <clipPath id='info-icon-outline'>
          <path fill='#fff' d='M0 0h14v14H0z' />
        </clipPath>
      </defs>
    </svg>
  );
}
