import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ColumnDef, createColumnHelper } from '@tanstack/react-table';

import { useInsightsContext } from 'components/insights/InsightsContainer';
import LocalExport from 'components/insights/layout/details/LocalExport';
import TableTitleContainer from 'components/insights/layout/details/TableTitleContainer';
import ViewBySelect from 'components/insights/layout/details/ViewBySelect';
import useInsightsQuery from 'components/insights/layout/helpers/useInsightsQuery';
import Histogram from 'components/shared/charts/Histogram';
import DataTable from 'components/shared/DataTable';
import useIsMobile from 'hooks/useIsMobile';
import { Analytics } from 'services/api/insights/analytics';
import { useInsightsStore } from 'stores/insightsStore';
import { LabelBold } from 'styles/typography';

import { DimensionConstants, MetricConstants, RouteConstants, SortOrder, SourceConstants } from '../../constants';
import Card from '../Card';
import { getRollup, parseDimensionValues, parseMetricValues } from '../helpers/dataUtils';

import { generateExtendedChartConfig } from './helpers/oesUtils';
import { GroupRow } from './helpers/utils';
import ChartContainer from './ChartContainer';
import MetricDetailContainer from './MetricDetailContainer';
import MetricDetailHeader from './MetricDetailHeader';
import TableContainer from './TableContainer';

export type OesTableRow = GroupRow & {
  score: number;
};

const OlioEngagementScoreDetail = () => {
  const { profile } = useInsightsContext();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const selectedGroupType = useInsightsStore((state) => state.selectedGroupType);

  const [selectedDimension, setSelectedDimension] = useState<DimensionConstants>(
    profile.isAcute ? DimensionConstants.PROVIDER_CLIENT : DimensionConstants.GROUP_NAME
  );

  const oesRequest = useMemo(
    () => ({
      params: {
        source: SourceConstants.LOCATION_EPISODE_DAYS,
        dimensions: [selectedDimension],
        metrics: [MetricConstants.OLIO_ENGAGEMENT_SCORE, MetricConstants.LOCATION_EPISODE_ID_COUNT],
        sortBy: `${MetricConstants.OLIO_ENGAGEMENT_SCORE} ${SortOrder.DESC}`,
        rollups: true,
      },
      processData: (data: Analytics) => {
        const parsedData = data.data.reduce(
          (acc, row) => {
            const dimensionValues = parseDimensionValues(row, false) as string[];
            const metricValues = parseMetricValues(row);

            const groupName = dimensionValues[0];
            const score = metricValues[0];
            const locationEpisodeCount = metricValues[1];

            // for overall OES, we want to average the group scores instead of displaying the true rollup
            if (row.grouping === 0) {
              acc.groupNames.push(groupName || 'None');
              acc.scores.push(score);
              acc.locationEpisodeCounts.push(locationEpisodeCount);
              acc.sumScores += score;
              acc.sumLocationEpisodes += locationEpisodeCount;
            }

            return acc;
          },
          { groupNames: [], locationEpisodeCounts: [], scores: [], sumScores: 0, sumLocationEpisodes: 0 } as {
            groupNames: string[];
            locationEpisodeCounts: number[];
            scores: number[];
            sumScores: number;
            sumLocationEpisodes: number;
          }
        );

        const [oesRollup] = getRollup(data.data);
        return { ...parsedData, overallScore: oesRollup };
      },
    }),
    [selectedDimension]
  );

  const { loading, query } = useInsightsQuery(oesRequest);

  const chartConfig = useMemo(
    () => generateExtendedChartConfig({ categories: query.data?.groupNames || [], values: query.data?.scores || [] }),
    [query.data?.groupNames, query.data?.scores]
  );

  const getValueString = () => {
    const overall = query.data?.overallScore ?? -1;
    if (overall < 0) return;

    return `${overall}%`;
  };

  const tableData = useMemo<OesTableRow[]>(
    () =>
      (query.data?.groupNames ?? []).map((groupName, i) => ({
        groupName,
        score: query.data?.scores?.[i] ?? 0,
        locationEpisodes: query.data?.locationEpisodeCounts?.[i] ?? 0,
      })),
    [query.data?.locationEpisodeCounts, query.data?.groupNames, query.data?.scores]
  );

  const getTableHeader = useMemo(() => {
    switch (selectedDimension) {
      case DimensionConstants.PLAN_TYPE:
        return 'Plan Type';
      case DimensionConstants.EPISODE_TYPE:
        return 'Episode Type';
      case DimensionConstants.PROVIDER_CLIENT:
        return `${selectedGroupType?.displayName} Company`;
      default:
        return selectedGroupType?.displayName;
    }
  }, [selectedDimension, selectedGroupType?.displayName]);

  const columnHelper = createColumnHelper<OesTableRow>();

  const columns = useMemo<ColumnDef<OesTableRow, any>[]>(
    () => [
      columnHelper.accessor('groupName', {
        header: getTableHeader,
        cell: (data) => <LabelBold>{data.getValue()}</LabelBold>,
      }),
      columnHelper.accessor('locationEpisodes', {
        header: 'Patients',
        footer: () => `${query.data?.sumLocationEpisodes} (Total)`,
      }),
      columnHelper.accessor('score', {
        header: 'Olio Engagement Score (OES)',
        cell: (data) => `${data.getValue()}%`,
        footer: () => `${query.data?.overallScore}%`,
      }),
    ],
    [columnHelper, getTableHeader, query.data?.sumLocationEpisodes, query.data?.overallScore]
  );

  const desktopOnlyColumns = ['locationEpisodes'];

  const defaultSort = [
    {
      id: 'score',
      desc: true,
    },
  ];

  return (
    <>
      <MetricDetailContainer
        onBackClick={() => navigate(RouteConstants.INSIGHTS_BASE)}
        loading={loading}
        hasData={!!query.data?.groupNames?.length}
        header={
          <>
            <MetricDetailHeader
              label='Olio Engagement Score (OES)'
              loading={loading}
              value={getValueString()}
              tooltipText='Measurement of engagement activity in Olio'
            />
            {!isMobile && (
              <ViewBySelect
                selectedDimension={selectedDimension}
                groupType={selectedGroupType}
                onChange={(selectedOption) => {
                  setSelectedDimension(selectedOption.value);
                }}></ViewBySelect>
            )}
          </>
        }>
        <ChartContainer>
          <Histogram config={chartConfig} />
        </ChartContainer>
      </MetricDetailContainer>
      <TableContainer>
        <TableTitleContainer>
          <Card.Title>Olio Engagement Score (OES) Overview</Card.Title>
          <LocalExport columns={columns} data={tableData} loading={loading} filePrefix='olio-engagement-score' />
        </TableTitleContainer>
        <DataTable
          columns={columns}
          data={tableData}
          defaultSortBy={defaultSort}
          desktopOnlyColumns={desktopOnlyColumns}
          loading={loading}
        />
      </TableContainer>
    </>
  );
};

export default OlioEngagementScoreDetail;
