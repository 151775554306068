import React, { useCallback, useState } from 'react';

import Attachment from 'models/Attachment';

import ImageViewer from './ImageViewer';
import Thumbnail from './Thumbnail';

type AttachedImageType = {
  attachment: Attachment;
  archived?: boolean;
};
export default function AttachedImage({ attachment, archived }: AttachedImageType) {
  const [modalState, setModalState] = useState(false);

  const handleToggleImageModal = useCallback(() => {
    if (archived) return;
    setModalState(!modalState);
  }, [modalState, archived]);

  return (
    <div>
      <Thumbnail attachment={attachment} onClick={handleToggleImageModal} archived={archived ?? false} />
      <ImageViewer attachment={attachment} onClose={handleToggleImageModal} show={modalState} />
    </div>
  );
}
