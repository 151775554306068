import { useMemo, useState } from 'react';

import OlioSolidGauge from 'components/shared/charts/OlioSolidGauge';

import { MetricConstants, RouteConstants, SourceConstants } from '../../constants';
import { DataRow, parseMetricValues } from '../helpers/dataUtils';
import useDataFetch from '../helpers/useDataFetch';

import MetricTile from './MetricTile';

export default function OlioEngagementScoreTile() {
  const [olioEngagementScore, setOlioEngagementScore] = useState(-1);

  const request = useMemo(
    () => ({
      params: {
        source: SourceConstants.LOCATION_EPISODE_DAYS,
        metrics: [MetricConstants.OLIO_ENGAGEMENT_SCORE],
      },
      processData: (data: DataRow[]) => {
        try {
          if (!data?.length) {
            setOlioEngagementScore(-1);
            return;
          }

          const [oesRollup] = parseMetricValues(data[0]);

          setOlioEngagementScore(oesRollup);
        } catch (error) {
          console.error('Error parsing Olio Engagement Score data', error);
        }
      },
    }),
    []
  );

  const { loading, customRef } = useDataFetch([request], { onIntersecting: true });

  return (
    <MetricTile
      ref={customRef}
      data-gtm-id='insightsOES'
      data-cy='insightsOES'
      label='Olio Engagement Score (OES)'
      tooltipText='Measurement of engagement activity in Olio'
      showValue={false}
      loading={loading}
      hasData={olioEngagementScore !== -1}
      navigateTo={RouteConstants.OLIO_ENGAGEMENT_SCORE}>
      <OlioSolidGauge value={olioEngagementScore} label={`${olioEngagementScore}%`} subLabel='OES Goal 100%' />
    </MetricTile>
  );
}
