import { GenericAbortSignal } from 'axios';

import { InfiniteData, useMutation, useQueryClient } from '@tanstack/react-query';

import { AnswerType } from 'components/modals/QuestionList/QuestionList';
import updateInfiniteQueryRecord from 'lib/updateInfiniteQueryRecord';
import { AcknowledgementOptions } from 'models/activities/Acknowledgement';
import ActivityModelFactory from 'models/activities/ActivityModelFactory';
import { AltcsApplicationStatusUpdateOptions } from 'models/activities/AltcsApplicationStatusUpdate';
import { AuthorizationUpdatesOptions } from 'models/activities/AuthorizationUpdates';
import { DischargeQuestionsOptions } from 'models/activities/DischargeQuestions';
import { NoteOptions } from 'models/activities/Note';
import { PatientFieldChangesOptions } from 'models/activities/PatientFieldChanges';
import { ProgressUpdateOptions } from 'models/activities/ProgressUpdate';
import { ProjectedDischargeUpdatesOptions } from 'models/activities/ProjectedDischargeUpdates';
import { RehabStateChangeOptions } from 'models/activities/RehabStateChange';
import { ServiceRefusalOptions } from 'models/activities/ServiceRefusal';
import Activity, { ActivityOptions, ActivityType } from 'models/Activity';
import Attachment from 'models/Attachment';
import { Note } from 'models/Notes';
import { Paginated } from 'models/Paginated';
import { attachmentsQueryKeys } from 'services/api/attachments';
import { BaseIndexQueryParams, BaseMutationParams } from 'services/api/types';
import http from 'services/http';

export const activityQueryKeys = {
  base: ['activities'],
  index: (params: Record<string, any> = {}) => ['activities', 'index', params],
};

type IndexParams = { locationEpisodeId: string } & BaseIndexQueryParams;
export async function indexActivities({ locationEpisodeId, ...params }: IndexParams, signal: GenericAbortSignal) {
  return http
    .get<
      Paginated<
        | Partial<ActivityOptions>
        | Partial<AcknowledgementOptions>
        | Partial<AltcsApplicationStatusUpdateOptions>
        | Partial<AuthorizationUpdatesOptions>
        | Partial<DischargeQuestionsOptions>
        | Partial<NoteOptions>
        | Partial<PatientFieldChangesOptions>
        | Partial<ProgressUpdateOptions>
        | Partial<ProjectedDischargeUpdatesOptions>
        | Partial<RehabStateChangeOptions>
        | Partial<ServiceRefusalOptions>
      >
    >(`/location_episodes/${locationEpisodeId}/activities`, { params, signal })
    .then((res) => ({
      ...res.data,
      data: res.data.data.map((x) => ActivityModelFactory.get(x)),
    }));
}

type CreateParams = { locationEpisodeId: string; type: ActivityType } & Note;
export async function createActivity({ locationEpisodeId, ...params }: CreateParams) {
  return http
    .post<ActivityOptions>(`/location_episodes/${locationEpisodeId}/activities`, params)
    .then((res) => new Activity(res.data));
}

type UpdateParams = {
  id: string;
  questions?: AnswerType[];
  archived?: boolean;
} & BaseMutationParams;
export async function updateActivity({ id, ...params }: UpdateParams) {
  return http.put<ActivityOptions>(`activities/${id}`, params).then((res) => ActivityModelFactory.get(res.data));
}

export const useUpdateActivity = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateActivity,
    onSuccess: (updatedActivity) => {
      queryClient.setQueriesData<InfiniteData<Paginated<Activity>>>(
        { queryKey: activityQueryKeys.index() },
        (prevData) => updateInfiniteQueryRecord(prevData, updatedActivity)
      );

      const removeCount = !!updatedActivity.archived;
      queryClient.setQueriesData<Paginated<Attachment>>({ queryKey: attachmentsQueryKeys.count() }, (prevData) => {
        if (!prevData) return prevData;
        return {
          ...prevData,
          meta: {
            ...prevData.meta,
            totalRecords:
              prevData.meta.totalRecords +
              (removeCount ? -updatedActivity.attachments.length : updatedActivity.attachments.length),
          },
        };
      });
    },
  });
};
