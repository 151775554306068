import { AnswerType } from 'components/modals/QuestionList/QuestionList';
import { parseDate } from 'lib/date';
import Attachment, { AttachmentOptions } from 'models/Attachment';
import Escalation, { EscalationOptions } from 'models/Escalation';
import Group, { GroupOptions } from 'models/Group';
import GroupType, { GroupTypeOptions } from 'models/GroupType';
import Patient, { PatientOptions } from 'models/Patient';

export enum ActivityType {
  ACKNOWLEDGEMENT = 'acknowledgement',
  ALTCS_APPLICATION_STATUS_UPDATE = 'altcs_application_status_update',
  AUTHORIZATION_UPDATES = 'authorization_updates',
  DISCHARGE_QUESTIONS = 'discharge_questions',
  NOTE = 'note',
  PATIENT_FIELD_CHANGES = 'patient_field_changes',
  PATIENT_MATCH_COLLABORATION = 'patient_match_collaboration',
  PROGRESS_UPDATE = 'progress_update',
  PROJECTED_DISCHARGE_UPDATES = 'projected_discharge_updates',
  REHAB_STATE_CHANGE = 'rehab_state_change',
  SERVICE_REFUSAL = 'service_refusal',
}
export const STATUS = 'status';

export type Changes = { current: string | null; previous: string | null; prop: string }[];

type CreatedBy = {
  id: string;
  fullName: string;
  locationType: string;
  groupType: Partial<GroupTypeOptions>;
};

export interface BaseActivityData {
  text: string;
  plaintext: string;
  mentions: Mention[];
  archived: boolean;
  createdByGroup: Group | null;
  createdBy: CreatedBy;
}

export type Mention = {
  id: string;
  display: string;
  index: number;
  childIndex: number;
  plainTextIndex: number;
};

export type BaseActivityDataOptions = {
  text: string;
  plaintext: string;
  mentions: Mention[];
  archived: boolean;
  createdByGroup: Partial<GroupOptions> | null;
  createdByUser: Partial<CreatedBy>;
  createdBy: Partial<CreatedBy>;
};

export interface ActivityOptions {
  id: string;
  createdAt: string | null;
  updatedAt: string | null;
  admittedAt: string | null;
  enteredAt: string | null;
  // Models inherting from the `Activity` class should define the shape of `data`
  data: Record<string, any>;
  createdBy: CreatedBy | null;
  createdByGroup: Partial<GroupOptions> | null;
  escalated: boolean;
  locationEpisodeId: string;
  patient: Partial<PatientOptions> | null;
  type: ActivityType;
  attachments: Partial<AttachmentOptions>[];
  escalation: Partial<EscalationOptions> | null;
  escalationType: string;
  questions?: AnswerType[];
  archived: boolean;
}

export function getDefaults(): ActivityOptions {
  return {
    id: '',
    data: {
      text: '',
      plaintext: '',
      mentions: [],
      createdByUser: {},
      createdByGroup: null,
    },
    createdBy: null,
    createdByGroup: null,
    escalated: false,
    locationEpisodeId: '',
    patient: null,
    type: ActivityType.NOTE,
    attachments: [],
    escalation: null,
    escalationType: '',
    admittedAt: null,
    enteredAt: null,
    createdAt: null,
    updatedAt: null,
    archived: false,
  };
}
/**
 * @class Activity
 * @classdesc Represents an activity in the system
 * @property {string} id - The activity's id
 * @property {Date} createdAt - The activity's creation date
 * @property {Date} updatedAt - The activity's update date
 * @property {Date} admittedAt - The activity's admission date
 * @property {Date} enteredAt - The activity's entry date
 * @property {BaseActivityData} data - The activity's data
 * @property {boolean} escalated - Whether the activity is escalated
 * @property {string} locationEpisodeId - The activity's location episode id
 * @property {Partial<PatientOptions>} patient - The activity's patient
 * @property {ActivityType} type - The activity's type
 * @property {Attachment[]} attachments - The activity's attachments
 * @property {Escalation} escalation - The activity's escalation
 * @param {Partial<ActivityOptions>} [options={}]
 * @example const activity = new Activity({ id: '123' });
 * @example const activity = new Activity({ id: '123', createdAt: '2022-01-01T00:00:00.000Z' });
 */
export default class Activity {
  id: string;
  createdAt: Date | null;
  updatedAt: Date | null;
  admittedAt: Date | null;
  enteredAt: Date | null;
  // Models inherting from this class should define the shape of `data`
  data: Record<string, any>;
  escalated: boolean;
  locationEpisodeId: string;
  patient: Patient | null;
  type: ActivityType;

  attachments: Attachment[];
  escalation: Escalation | null;
  escalationType: string;
  archived: boolean;

  constructor(options: Partial<ActivityOptions> = {}) {
    const defaults = getDefaults();
    const opts = { ...defaults, ...options, data: { ...defaults.data, ...(options.data ?? {}) } };

    this.id = opts.id;
    this.escalationType = opts.escalationType;
    this.admittedAt = parseDate(opts.admittedAt) ?? null;
    this.createdAt = parseDate(opts.createdAt) ?? null;
    this.updatedAt = parseDate(opts.updatedAt) ?? null;
    this.enteredAt = parseDate(opts.enteredAt) ?? null;
    this.archived = opts.archived;

    const createdByGroup = opts.createdByGroup ?? opts.data.createdByGroup;
    this.data = {
      ...opts.data,
      // we return the createdBy data multiple ways in our api
      // sometimes under the key createdBy, sometimes under createdByUser, and then sometimes nested in the `data` object
      createdByUser: opts.createdBy ?? opts.data.createdBy ?? opts.data.createdByUser,
      createdByGroup: createdByGroup ? new Group(createdByGroup) : null,
    };

    this.escalated = opts.escalated;
    this.locationEpisodeId = opts.locationEpisodeId;
    this.patient = opts.patient ? new Patient(opts.patient) : null;
    this.type = opts.type;
    this.attachments = opts.attachments.map((x) => new Attachment(x));

    const { escalated, escalation, data } = opts;
    if (escalation || escalated) {
      const { plaintext, text } = data;
      this.escalation = new Escalation({
        ...(escalation || {}),
        text: plaintext || text,
      });
    }
  }

  get createdBy() {
    return this.data.createdByUser;
  }

  get createdByGroup() {
    return this.data.createdByGroup;
  }

  get createdByGroupType() {
    const groupType = this.createdBy?.groupType;

    return groupType ? new GroupType(groupType) : null;
  }

  get typeLabel() {
    return 'Note';
  }

  get ageInHours() {
    if (!this.createdAt) return null;
    return Math.floor((new Date().getTime() - new Date(this.createdAt).getTime()) / 1000 / 60 / 60);
  }

  get edited() {
    if (!this.updatedAt || !this.createdAt) return false;
    return this.updatedAt > this.createdAt;
  }
}
