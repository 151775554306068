import styled from 'styled-components';

import colors from 'styles/theme/colors';
import InfoIconOutline from 'svg/InfoIconOutline';

// Right now the only 'type' of alert is the info styling.
// Once we have the need for multiple types, we can modify this component to support it.
function Alert({ children, ...props }: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <Container {...props}>
      <IconContainer>
        <InfoIconOutline fill='#243AA5' />
      </IconContainer>
      <div>{children}</div>
    </Container>
  );
}

const Text = styled.p`
  font-size: 12px;
  line-height: 20px;
  color: #243aa5;
`;

const Container = styled.div`
  background-color: ${colors.primaryBlue05};
  border: 1px solid ${colors.primaryBlue10};
  border-radius: 3px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
`;

Alert.Text = Text;
export default Alert;
