import { useState } from 'react';
import styled from 'styled-components';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import ActivityInput from 'components/shared/activityInput/ActivityInput';
import useActivityInput from 'components/shared/activityInput/useActivityInput';
import Alert from 'components/shared/Alert';
import Button from 'components/shared/Button';
import InputGroup from 'components/shared/form/InputGroup';
import StandardModal, { Actions, ColorThemeName, PatientName } from 'components/shared/StandardModal';
import LocationEpisode from 'models/LocationEpisode';
import { ReviewType } from 'models/Review';
import { activityQueryKeys } from 'services/api/activity';
import { locationEpisodeQueryKeys } from 'services/api/locationEpisodes';
import { createReview } from 'services/api/reviews';
import { BodySmallBold } from 'styles/typography';
import HhaRefuseIcon from 'svg/HhaRefuseIcon';
import PatientRefuseIcon from 'svg/PatientRefuseIcon';

enum WhoRefuseService {
  Unset = '', //ensure falsiness
  Patient = 'patient',
  Practice = 'practice',
}

export type Props = {
  setShow: (b: boolean) => void;
  patientName: string;
  locationEpisode: LocationEpisode;
};

export default function ServiceRefusalModal({ setShow, patientName, locationEpisode }: Props) {
  const queryClient = useQueryClient();
  const [whoIsRefusingServices, setWhoIsRefusingServices] = useState(WhoRefuseService.Unset);
  const { note, setNote, resetNote, getSerializedNoteValues } = useActivityInput();
  const [isUploading, setIsUploading] = useState(false);
  const cancel = () => {
    setWhoIsRefusingServices(WhoRefuseService.Unset);
    setShow(false);
    resetNote();
  };

  const { mutate, isPending } = useMutation({
    mutationFn: createReview,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: activityQueryKeys.base });
      queryClient.invalidateQueries({ queryKey: locationEpisodeQueryKeys.show({ id: locationEpisode.id }) });
      cancel();
    },
  });

  const submitDisabled = !whoIsRefusingServices || isUploading;

  const submit = () => {
    const serializedNote = getSerializedNoteValues();
    delete serializedNote.escalationType;

    mutate({
      locationEpisodeId: locationEpisode.id,
      type: ReviewType.SERVICE_REFUSAL,
      note: serializedNote,
      refusedBy:
        whoIsRefusingServices == WhoRefuseService.Patient
          ? { ...locationEpisode.patient, type: 'patient' }
          : {
              ...locationEpisode.rehabInformation.latestRehabFacility,
              type: 'rehab_facility',
            },
      include: 'activities.attachments',
    });
  };

  const rehabFacilityName = locationEpisode.rehabInformation.latestRehabFacility.name;
  const innerPatientName = locationEpisode.patient.name;

  return (
    <StandardModal colorTheme={ColorThemeName.Info} title='Refuse Services' onCancel={cancel}>
      <PatientName>Patient: {patientName}</PatientName>
      <FormContainer>
        <InputGroup title='Who is refusing services at this time?'>
          <StatusContainer>
            <StatusButton
              $selected={whoIsRefusingServices == WhoRefuseService.Practice}
              onClick={() => setWhoIsRefusingServices(WhoRefuseService.Practice)}>
              <HhaRefuseIcon />
              <TextContainer>{rehabFacilityName}</TextContainer>
            </StatusButton>
            <StatusButton
              $selected={whoIsRefusingServices == WhoRefuseService.Patient}
              onClick={() => setWhoIsRefusingServices(WhoRefuseService.Patient)}>
              <PatientRefuseIcon />
              <TextContainer>{innerPatientName}</TextContainer>
            </StatusButton>
          </StatusContainer>
        </InputGroup>
        {whoIsRefusingServices && (
          <Alert style={{ marginBottom: 24 }}>
            <Alert.Text>
              Confirm refusal of services to notify collaborators. Patient will remain in Queue until a collaborator has
              confirmed or reassigned patient.
            </Alert.Text>
          </Alert>
        )}
        <InputGroup title='Notes (optional)'>
          <ActivityInput
            locationEpisodeId={locationEpisode.id}
            values={note}
            setValues={setNote}
            onUploading={setIsUploading}
          />
        </InputGroup>
      </FormContainer>
      <Actions>
        <Button variant='ghost' onClick={cancel}>
          Cancel
        </Button>
        <Button onClick={submit} disabled={submitDisabled} loading={isPending}>
          Confirm
        </Button>
      </Actions>
    </StandardModal>
  );
}

const FormContainer = styled.div`
  padding-bottom: 25px;
`;

const StatusContainer = styled.div`
  display: flex;
  gap: 8px;
`;
const StatusButton = styled.div<{ $selected: boolean }>`
  flex: 1;
  gap: 8px;
  padding: 8px 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 0;
  font-weight: var(--font-weight-bold);
  border-width: 2px;
  border-radius: ${({ theme }) => theme.dimensions.borderRadius};
  border-style: solid;
  border-color: ${({ $selected }) => ($selected ? 'var(--primary-blue)' : 'transparent')};
  color: ${({ $selected }) => ($selected ? 'var(--primary-blue)' : 'var(--black)')};
  background-color: ${({ $selected }) => ($selected ? 'var(--primary-blue-10)' : 'var(--black-05)')};
  &:hover {
    cursor: pointer;
    background-color: var(--primary-blue-10);
  }
`;

const TextContainer = styled(BodySmallBold)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 18px;
  height: 18px;
`;
