type DataValues = { value: number | string | null }[];

export type DataRow = {
  dimensionValues: DataValues | [];
  metricValues: DataValues;
  grouping?: number;
};

export const parseDimensionValues = ({ dimensionValues }: DataRow, asNumber = true) =>
  dimensionValues.map(({ value }) => (asNumber ? Number(value ?? -1) : value));

export const parseMetricValues = ({ metricValues }: DataRow) => metricValues.map(({ value }) => Number(value ?? -1));

export const getRollupRow = (data: DataRow[]) => {
  const result = data.reduce(
    (accumulator, row) => ((accumulator.grouping ?? -1) > (row.grouping ?? -1) ? accumulator : row),
    {} as DataRow
  );

  return result.grouping ? result : null;
};

export const getRollup = (data: DataRow[], { dataParser = parseMetricValues } = {}) => {
  const rollup = getRollupRow(data);

  return rollup ? dataParser(rollup) : [];
};

export const getNonRollups = (data: DataRow[]) => {
  const rollup = getRollupRow(data);

  if (!rollup) {
    return data;
  }

  return data.filter((row: DataRow) => (row.grouping ?? -1) < (rollup?.grouping ?? -1));
};
